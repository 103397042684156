import React, { useRef } from "react";
import _ from "lodash";
import { normalizeFq, normalizeFqToNum, onCopyClick } from "../helpers";
import { connect, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import NoFQData from "./parts/NoFQData";
import CopyFeedback from "./CopiedTooltip";

const findName = (name, query) => {
  return name.toLowerCase().indexOf(query) !== -1;
};

const FQ_PER_PAGE = 30;
class TableView extends React.Component {
  state = {
    activeName: null,
    searchFQValue: "",
    searchNAMESValue: "",
    page: 0,
  };

  constructor(props) {
    super(props);
    this.searchRef = React.createRef();
  }

  componentDidMount() {
    this.searchRef.current.focus();
  }

  onClick = (n) => () => {
    this.setState({
      activeName: n,
    });
  };
  onSearchFQChange = ({ target }) => {
    const newValue = target.value
      .trim()
      .replace(",", ".")
      .replace("/", ".")
      .replace("ю", ".")
      .replace("б", ".")
      .replace(":", ".")
      .replace("?", ".")
      .replace(/[^0-9\.]/g, "");

    if (newValue === this.state.searchValue) return;

    this.setState({
      searchFQValue: newValue,
      searchNAMESValue: "",
      page: 0,
    });
  };
  onSearchNAMESChange = ({ target }) => {
    const newValue = target.value
      .toLowerCase()
      .trimLeft()
      .replace(",", ".")
      .replace("/", ".");

    if (newValue === this.state.searchValue) return;

    this.setState({
      searchNAMESValue: newValue,
      searchFQValue: "",
      page: 0,
    });
  };

  render() {
    const { searchFQValue, searchNAMESValue, activeName } = this.state;
    const { allNames, sortedFqList, computed } = this.props;

    const normalizedSearchFQValue = searchFQValue.trim();
    const normalizedSearchNAMESValue = searchNAMESValue.trim();
    const dataList =
      normalizedSearchFQValue || normalizedSearchNAMESValue
        ? _.pickBy(allNames, (names, fq) => {
            if (normalizedSearchFQValue) {
              const n = normalizeFq(fq);
              return (
                n.indexOf(normalizedSearchFQValue) === 0 ||
                (n + "0000").indexOf(normalizedSearchFQValue) === 0
              );
            } else if (normalizedSearchNAMESValue) {
              return _.some(names, (n) =>
                findName(n, normalizedSearchNAMESValue)
              );
            }
          })
        : allNames;

    const preparedFqList = sortedFqList.filter(
      (fq) => dataList[fq] !== undefined
    );

    return (
      <div className="fq-table-container">
        <div className="fq-table__search">
          <div className="fq-table__search-field fq-table__search-fq"></div>
          <div className="fq-table__search-field fq-table__search-names"></div>
        </div>

        <div className="fq-table-panel">
          {activeName &&
            computed.nameToFs[activeName].map((fq) => (
              <span style={{ marginRight: "30px" }}>{fq}</span>
            ))}
          &nbsp;
        </div>
        <>
          <table className="table table-stripped table-bordered table-hover table-sm fq-table-table">
            <tbody>
              <tr className={"fq-table__searches"}>
                <th className={"fq-navigate-cell fq-navigate-cell__search"}>
                  <TextField
                    margin="dense"
                    size={"small"}
                    // key={`${proj}[${name}]`}
                    label={"пошук по частоті"}
                    type="search"
                    variant="outlined"
                    inputProps={{
                      ref: this.searchRef,
                      autoComplete: "off",
                    }}
                    value={normalizedSearchFQValue}
                    // value={}
                    onInput={this.onSearchFQChange}
                  />
                </th>
                <td>
                  <TextField
                    margin="dense"
                    size={"small"}
                    // key={`${proj}[${name}]`}
                    label={"пошук по позивним"}
                    type="search"
                    variant="outlined"
                    inputProps={{
                      autoComplete: "off",
                    }}
                    sx={{ width: "100%" }}
                    value={normalizedSearchNAMESValue}
                    // value={}
                    onInput={this.onSearchNAMESChange}
                  />
                </td>
              </tr>
              {preparedFqList
                .slice(
                  this.state.page * FQ_PER_PAGE,
                  (this.state.page + 1) * FQ_PER_PAGE
                )
                .map((fq) => {
                  const names = dataList[fq];
                  // console.log(names, fq)
                  const fullFqValue = normalizeFq(fq);

                  return (
                    <Row
                      key={fq}
                      fullFqValue={fullFqValue}
                      nameToFs={computed.nameToFs}
                      names={names}
                      maxValue={computed.maxValue}
                      activeName={activeName}
                      onClick={this.onClick}
                      searchValue={normalizedSearchNAMESValue}
                    />
                  );
                })}
            </tbody>
          </table>
          <nav>
            <ul className="pagination justify-content-center">
              {preparedFqList.length / FQ_PER_PAGE >= 2 &&
                _.times(preparedFqList.length / FQ_PER_PAGE, (i) => {
                  return (
                    <li
                      className={
                        "page-item " + (i === this.state.page ? "active" : "")
                      }
                      key={i}
                    >
                      <a
                        className={"page-link"}
                        onClick={(ev) => {
                          ev.preventDefault();
                          this.setState({
                            page: i,
                          });
                        }}
                      >
                        {i + 1}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </nav>
        </>
        {preparedFqList.length === 0 && (
          <div className={"fq-table__noresults"}>
            {normalizedSearchFQValue && (
              <NoFQData fq={normalizeFq(normalizedSearchFQValue)} />
            )}
            {normalizedSearchNAMESValue && (
              <div>
                Не знайденно жодних результатів з «{normalizedSearchNAMESValue}»
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const Row = (props) => {
  const {
    fullFqValue,
    nameToFs,
    names,
    searchValue,
    maxValue,
    activeName,
    onClick,
  } = props;
  return (
    <tr>
      <NavigateCell
        id={fullFqValue}
        // title={formatTooltip(g, rayon)}
        value={fullFqValue}
      />

      <td className={"fq-names-cell"}>
        {names.map((n, i) => {
          // if (!nameToFs[n]) {
          //   console.log('!', n)
          // }
          return (
            <Name
              key={n}
              isMatchSearchValue={searchValue && findName(n, searchValue)}
              isActiveName={activeName === n}
              isLast={i === names.length - 1}
              i={i}
              n={n}
              maxValue={maxValue}
              nameToFs={nameToFs[n]}
              onClick={onClick}
            />
          );
        })}
      </td>
    </tr>
  );
};
const Name = React.memo(
  ({
    isMatchSearchValue,
    isActiveName,
    n,
    maxValue,
    nameToFs,
    isLast,
    onClick,
  }) => {
    // console.log('name')
    // const getBGColor = () => {
    //     // if (n[0].toLowerCase() === n[0]) return 'aqua'
    //     if (n.indexOf(' ') !== -1) return '#acd5ff'
    //     if (n.indexOf('-') !== -1) return '#acffcb'
    //     // if (n.toLowerCase().search(/ҐЄІЇ/)) return '#acffcb'
    // }
    let style = {
      fontSize: 60 + (70 / maxValue) * (nameToFs || "").length + "%",
    };
    if (isMatchSearchValue) {
      style.backgroundColor = "#c86000";
    }
    if (isActiveName) {
      style.textDecoration = "underline";
    }

    return (
      <>
        <span style={style} onClick={onClick(n)}>
          {n}
        </span>
        {!isLast ? ", " : ""}
      </>
    );
  },
  (prev, next) => {
    return (
      prev.isMatchSearchValue === next.isMatchSearchValue &&
      prev.isActiveName === next.isActiveName
    );
  }
);

const NavigateCell = React.memo(
  ({ value, id, title }) => {
    const navigate = useNavigate();
    const chat_title = useSelector(
      ({ frequencies }) => (frequencies[normalizeFqToNum(id)] || {}).chat_title
    );
    const feedbackRef = useRef();
    return (
      <th
        onClick={() => navigate("/fq/" + id)}
        // title={title}
        className="fq-navigate-cell"
      >
        <span>{value}</span>
        {chat_title && <i>{chat_title}</i>}
        <div
          title={"Копіювати шапку для чату"}
          className="fq-navigate-cell__button"
          onClick={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
            onCopyClick({
              fq: id,
              chat_title,
            });
            feedbackRef.current.show();
          }}
        >
          <CopyFeedback ref={feedbackRef} />
        </div>
      </th>
    );
  },
  () => true
);

const formatTooltip = (g, rayon) => {
  return [g, rayon].join("\n----\n");
};

export default connect(({ names }) => ({
  allNames: names.list,
  computed: names.computed,
  sortedFqList: names.sortedFqList,
}))(TableView);
