import { actionHandlers } from "../helpers";
import _ from "lodash";

export default actionHandlers(
  {
    ["FREQUENCIES_SNAPSHOT"]: (state, payload) => {
      return payload;
    },
    ["FREQUENCIES_UPDATE"]: (state, payload) => {
      return {
        ...state,
        ...payload,
      };
    },
    ["FREQUENCIES_REMOVE"]: (state, payload) => {
      return _.omitBy((v, id) => payload.includes(id));
    },
  },
  {}
);
