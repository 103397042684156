import { applyMiddleware, combineReducers, createStore } from "redux";
import { createLogger } from "redux-logger";
import profileReducer from "./reducers/profile";
import namesReducer from "./reducers/names";
import frequenciesReducer from "./reducers/frequencies";
import daystoryReducer from "./reducers/daystory";
import { __DEV__ } from "./constants";

const logger = createLogger({
  // diff: __DEV__,
  collapsed: true,
});

const store = createStore(
  combineReducers({
    profile: profileReducer,
    names: namesReducer,
    frequencies: frequenciesReducer,
    daystory: daystoryReducer,
  }),
  applyMiddleware(logger)
);

window.store = store;

export default store;
