import React from "react";
import "../index.css";
import { connect } from "react-redux";

class CrossTable extends React.Component {
  state = {
    activeFQ: null,
  };
  setActiveFQ = (fq) => () =>
    this.setState({
      activeFQ: fq === this.state.activeFQ ? null : fq,
    });

  render() {
    const { activeFQ } = this.state;
    const { cross } = this.props;

    return (
      <table className="table table-stripped table-bordered table-hover table-sm">
        <tbody>
          {cross
            .filter(([fq1, fq2, cross]) => {
              if (activeFQ && cross.length <= 1) return;
              if (!activeFQ) return true;
              return fq1 === activeFQ || fq2 === activeFQ;
            })
            .map(([fq1, fq2, cross]) => {
              cross = cross.join(", ");
              if (activeFQ && activeFQ !== fq1) {
                fq2 = fq1;
                fq1 = activeFQ;
              }

              return (
                <tr key={fq1 + fq2 + cross}>
                  <th onClick={this.setActiveFQ(fq1)}>
                    {fq1}
                    {activeFQ === fq1 && "*"}
                  </th>
                  <th onClick={!activeFQ && this.setActiveFQ(fq2)}>
                    {fq2}
                    {activeFQ === fq2 && "*"}
                  </th>
                  <td>{cross}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  }
}

export default connect(({ names }) => ({ cross: names.cross }))(CrossTable);
