import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { __DEV__ } from "../../constants.js";
import _ from "lodash";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import dateFnsLocaleUk from "date-fns/locale/uk";

const Activity = React.memo(({ email, team }) => {
  const [timeoutID, setTimeoutID] = useState(null);

  let [value, loading, error, snapshot] = useDocumentData(
    doc(db, "usersActivity", team)
  );
  if (error) value = {};

  const yesterday = new Date().valueOf() - 1000 * 60 * 60 * 24;
  const filteredActivity = _.omitBy(value, (v, k) => {
    if (k === email) return true;
    if (!__DEV__ && v < yesterday) return true;
    return false;
  });

  const force = () =>
    setTimeoutID(
      setTimeout(
        force,
        process.env.NODE_ENV === "development" ? 5 * 1000 : 60 * 1000
      )
    );

  useEffect(() => {
    force();
    return () => clearTimeout(timeoutID);
  }, []);

  if (error || !email || _.isEmpty(filteredActivity)) return null;

  return (
    <div className={"vuho-activity"}>
      {_.keys(filteredActivity)
        .sort()
        .map((email) => {
          const time = filteredActivity[email];
          return (
            <span key={email}>
              {email} (
              {formatDistanceToNow(time, {
                includeSeconds: true,
                locale: dateFnsLocaleUk,
              })}
              )
            </span>
          );
        })}
    </div>
  );
});

export const reportUserActivity = _.throttle(
  () => {
    return null;
    const { email, team, authorised } = window.store.getState().profile;
    if (!authorised) return;

    const data = {
      [email]: new Date().valueOf(),
    };

    setDoc(doc(db, "usersActivity", team), data, { merge: true });
  },
  10 * 1000,
  { leading: true }
);

export default () => {
  return null;
  const { email, team, authorised } = useSelector(({ profile }) => profile);

  if (authorised) {
    return <Activity email={email} team={team} authorised={authorised} />;
  } else {
    return null;
  }
};
