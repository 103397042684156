import React from "react";
import { connect, useSelector } from "react-redux";
import _ from "lodash";

async function doAllSequentually(fnPromiseArr) {
  for (let i = 0; i < fnPromiseArr.length; i++) {
    const val = await fnPromiseArr[i]();
    // console.log(val);
  }
}

const extractRole = (name) => {
  let role;
  const roleMatch = name.match(/\(([^)]+)\)/);
  role = roleMatch ? roleMatch[1] : "";

  if (role) {
    name = name.slice(0, roleMatch.index - 1).trim();
  }
  return [name, role];
};

class FireStoreHelper extends React.Component {
  render() {
    return (
      <div>
        <h1>FireStoreHelper</h1>
        {/*<div>*/}
        {/*  <button className="btn btn-default" onClick={this.uploadCSVNames}>*/}
        {/*    upload csv-names to database*/}
        {/*  </button>*/}
        {/*</div>*/}
        <div>
          <button className="btn btn-default" onClick={this.uploadCSVGRayon}>
            upload csv-g-rayon to database
          </button>
        </div>
      </div>
    );
  }
  // uploadCSVGRayon = () => {
  //   const { data, cross, computed } = this.props;
  //   const dataSet = _(data).reduce((acc, { g, rayon }, fq) => {
  //     if (g === "" && rayon === "") return acc;
  //     acc.push({ identification: g, location: rayon, fq });
  //     return acc;
  //   }, []);
  //   // .slice(0, 10);
  //
  //   console.log("UPLOAD", dataSet);
  //   // return;
  //
  //   if (window.confirm("Upload?")) {
  //     dataSet.forEach(({ fq, identification, location }) => {
  //       console.log(fq, { identification, location });
  //       setDoc(
  //         doc(db, "frequencies", fq),
  //         {
  //           identification,
  //           location,
  //         },
  //         { merge: true }
  //       );
  //     });
  //   }
  // };

  // uploadCSVNames = () => {
  //   const { data, cross, computed } = this.props;
  //   const dataSet = _(data)
  //     .mapValues(({ names }, fq) => {
  //       return names;
  //     })
  //     .pickBy((names, fq) => names.length > 0 && fq !== "Хвиля")
  //     .value();
  //
  //   console.log("UPLOAD", dataSet);
  //
  //   if (window.confirm("Upload?")) {
  //     setDoc(doc(db, "names", team), dataSet);
  //   }
  // };
}

export default connect(({ meta }) => ({ data: meta }))(FireStoreHelper);

export const Backup = () => {
  const names = useSelector(({ names }) => names.list);

  return (
    <textarea
      name=""
      id=""
      cols="30"
      rows="10"
      style={{ width: "100%", height: "500px" }}
      defaultValue={_.map(names, (list, fq) => {
        return `${fq}\t${list.join(", ")}`;
      }).join("\n")}
    />
  );
};
