import Button from "@mui/material/Button";
import { sk42ToWgs84 } from "../helpers";
import Map from "../Map/Map";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { v4 } from "uuid";

const getPossiblePoints = (x, y, zone, height, decodingKeys) => {
  const { lat, lng } = sk42ToWgs84({ x, y, zone });
};

const Decoder = () => {
  const [objects, setObjects] = useState([]);
  const [x, setX] = useState(54);
  const [y, setY] = useState(74);
  const [height, setHeight] = useState(0);
  const [decodingKeys, setDecodeKeys] = useState("5, 9, 10, 16, 20");

  const decode = async () => {
    const possiblePoints = [];
    const decodingKeysNums = decodingKeys
      .split(",")
      .map((dKey) => +dKey.trim());

    for (let decodingKey of decodingKeysNums) {
      let possibleX = x.toString().split("");
      const decoded23x = possibleX[2] + possibleX[3] - decodingKey;
      possibleX = +(
        "54" +
        decoded23x +
        possibleX[4] +
        possibleX[5] +
        possibleX[6]
      );

      let possibleY = y.toString().split("");
      const decoded23y = possibleY[2] + possibleY[3] - decodingKey;
      possibleY = +(
        "74" +
        decoded23y +
        possibleY[4] +
        possibleY[5] +
        possibleY[6]
      );

      possiblePoints.push({
        key: v4(),
        type: "point",
        value: {
          x: possibleX,
          y: possibleY,
          zone: 7,
        },
        label: `x: ${possibleX}, y: ${possibleY}, height: ${height}`,
      });
    }

    possiblePoints.push({
      key: v4(),
      type: "point",
      value: {
        x: x,
        y: y,
        zone: 7,
      },
      label: `x: ${x}, y: ${y}, height: ${height}`,
    });

    possiblePoints.push(
      { type: "zoom", value: 10 },
      {
        type: "center",
        value: {
          x: x,
          y: y,
          zone: 7,
        },
      }
    );

    setObjects(possiblePoints);
  };

  return (
    <div className="maptab">
      <div
        style={{
          marginTop: "40px",
          padding: "8px 10px",
          display: "flex",
          columnGap: "6px",
        }}
      >
        <TextField
          type="number"
          label="X"
          size="small"
          value={x}
          onChange={(e) => setX(e.target.value)}
        ></TextField>
        <TextField
          type="number"
          label="Y"
          size="small"
          value={y}
          onChange={(e) => setY(e.target.value)}
        ></TextField>
        <TextField
          type="number"
          label="Висота"
          size="small"
          value={height}
          onChange={(e) => setHeight(e.target.value)}
        ></TextField>
        <TextField
          label="Ключі декодування"
          size="small"
          value={decodingKeys}
          onChange={(e) => setDecodeKeys(e.target.value)}
        ></TextField>
        <Button color="secondary" variant="outlined" onClick={decode}>
          декодувати
        </Button>
      </div>
      <div className="maptab__map">
        <Map
          objects={objects}
          center={{
            lat: 49.0228,
            lng: 37.9649,
          }}
        />
      </div>
    </div>
  );
};

export default Decoder;
