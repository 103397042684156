import React from "react";
import { formatCK42 } from "../helpers";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ConversionField from "./ConversionField";
import _ from "lodash";

const PROJ = {
  CK42: "CK42",
  WGS84: "WGS84",
  UTM: "UTM",
};

const CoordsRow = ({ state, dispatch, elevation, prepend, prependRow }) => {
  return (
    <div className="maptab__conversion">
      <div className="maptab__conversion-row">
        {prepend}
        <div className="maptab__conversion_proj maptab__utm">
          <ConversionField
            label={"utm easting"}
            state={state}
            dispatch={dispatch}
            proj={PROJ.UTM}
            name={"easting"}
            width={65}
            maxLength={6}
            // disabled
          />
          <ConversionField
            label={"utm northing"}
            state={state}
            dispatch={dispatch}
            proj={PROJ.UTM}
            name={"northing"}
            width={75}
            maxLength={7}
            // disabled
          />
          {/*<ConversionField
          label={"zone letter"}
          state={state}
          dispatch={dispatch}
          proj={PROJ.UTM}
          name={"zoneLetter"}
          number={false}
          width={65}
          disabled
        />
        <ConversionField
          label={"zone"}
          state={state}
          dispatch={dispatch}
          proj={PROJ.UTM}
          name={"zoneNum"}
          width={65}
          disabled
        />*/}
        </div>
        <div className="maptab__conversion_proj maptab__wgs84">
          <ConversionField
            label={"latitude"}
            state={state}
            dispatch={dispatch}
            proj={PROJ.WGS84}
            name={"lat"}
            maxLength={7}
            width={140}
            disabled
          />
          <ConversionField
            label={"longitude"}
            state={state}
            dispatch={dispatch}
            proj={PROJ.WGS84}
            name={"lng"}
            maxLength={7}
            width={140}
            disabled
          />
        </div>
        <div className="maptab__conversion_proj maptab__ck42">
          <ConversionField
            label={"X"}
            format={(v) => formatCK42(v)}
            state={state}
            dispatch={dispatch}
            proj={PROJ.CK42}
            name={"x"}
            maxLength={7}
            width={60}
            disabled
            number={false}
          />
          <ConversionField
            label={"Y"}
            format={(v) => formatCK42(v)}
            state={state}
            dispatch={dispatch}
            proj={PROJ.CK42}
            name={"y"}
            maxLength={7}
            width={60}
            disabled
            number={false}
          />
          {/*<ConversionField
            label={"zone"}
            state={state}
            dispatch={dispatch}
            proj={PROJ.CK42}
            name={"zone"}
            disabled
            maxLength={1}
            width={35}
          />*/}
        </div>
        <div className="maptab__conversion_proj">
          <ConversionField
            state={{ fields: { elevation } }}
            dispatch={dispatch}
            proj={"fields"}
            name={"elevation"}
            label={"Висота"}
            format={(v) =>
              typeof elevation === "number" && !_.isNaN(elevation)
                ? parseFloat(v).toFixed(2) + " м"
                : ""
            }
            width={65}
            disabled
            number={false}
          />
        </div>
      </div>
      {/*{state[PROJ.WGS84].lat && (
        <div className="maptab__conversion-row">
          <div className="maptab__conversion_proj maptab__conversion_proj-wgs84str">
            wgs84:{" "}
            <i>
              {state[PROJ.WGS84].lat}, {state[PROJ.WGS84].lng}
            </i>
          </div>
          <div className="maptab__conversion_proj">
            <Button variant="text" size={"small"} onClick={copyMessage}>
              копіювати
            </Button>
          </div>
        </div>
      )}*/}
    </div>
  );
};

export default CoordsRow;
