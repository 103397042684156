import CreateMessage from "./CreateMessage";
import Message from "./Message";
import { useWriteAccess } from "../../../hooks";
import { useState } from "react";
// import Map from "../../parts/Map";

const MessagesTab = ({ fqID }) => {
  const writeAccess = useWriteAccess();

  const [message, setMessage] = useState(null);

  return (
    <div className={"messages-container"}>
      <div style={{ height: 500 }}></div>

      {writeAccess && (
        <CreateMessage fqID={fqID} onMessageChange={(m) => setMessage(m)} />
      )}

      {message && <Message data={Message} />}
    </div>
  );
};

export default MessagesTab;
