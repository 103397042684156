import TextareaAutosize from "react-textarea-autosize";
import React, { useRef, useState } from "react";

const CreateMessage = ({ onMessageChange, fqID }) => {
  const abonents = [];
  const groups = [];

  return (
    <div className={"create-message"}>
      <Top />
      <TextAndComment />
      <div className="create-message__abonents">
        {abonents.map(({ name, bearing }, i) => {
          return <Abonent key={name + i} name={name} bearing={bearing} />;
        })}
      </div>
      <div className="create-message__groups">
        {groups.map(({ name, id }) => {})}
      </div>
    </div>
  );
};
const Publish = () => {};

const Top = () => {
  return (
    <div className="create-message__top">
      <div className="create-message__fq"></div>
      <div className="create-message__title"></div>
      <DateTime />
    </div>
  );
};
const DateTime = () => {
  return <div className="create-message__datetime"></div>;
};

const TextAndComment = () => {
  const fieldRef = useRef();
  const [value, setValue] = useState();
  const save = () => {};
  return (
    <>
      <div className="create-message__text">
        <TextareaAutosize
          ref={fieldRef}
          className="form-control fq-field__textarea"
          id={`create-message`}
          value={value}
          onChange={({ target }) => setValue(target.value)}
          onBlur={({ target }) => {
            if (target.value !== value) {
              setValue(target.value, save);
            }
          }}
        />
      </div>
      <div className="create-message__comment"></div>
    </>
  );
};
const Abonents = () => {};

const Abonent = ({ name, bearing }) => {
  return (
    <div className="create-message__abonent">
      <input type="text" value={name} />
      {/* TODO: tabs */}
      {bearing.type === "point" && (
        <div className="create-message__point"></div>
      )}
      {bearing.type === "vector" && (
        <div className="create-message__vector"></div>
      )}
    </div>
  );
};

export default CreateMessage;
