import { shallowEqual, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { normalizeFq } from "../../helpers";

const getButtonClass = (distance, isCloser) => {
  if (distance > 1.5) return "secondary";
  if (isCloser) return "warning";
  return "primary";
};

function NoFQData({ fq, callback }) {
  const fqMap = useSelector(({ frequencies }) => {
    const selectedFQ = fq.toString();
    const frequenciesKeys = Object.keys(frequencies);

    if (frequenciesKeys.indexOf(selectedFQ) === -1) {
      frequenciesKeys.push(selectedFQ);
    }

    const sortedFqList = frequenciesKeys.sort(
      (a, b) => parseFloat(a) - parseFloat(b)
    );
    const fqIndex = sortedFqList.indexOf(selectedFQ);
    return {
      left: parseFloat(sortedFqList[fqIndex - 1]),
      right: parseFloat(sortedFqList[fqIndex + 1]),
    };
  }, shallowEqual);

  const leftDistance = fq - fqMap.left;
  const rightDistance = fqMap.right - fq;
  const difference = leftDistance - rightDistance;

  return (
    <div className="pb-5 text-center fqnodata">
      <p>
        Не знайдено інформації по {normalizeFq(fq)}. Оберіть сусідню частоту,
        або створіть{" "}
        <Link
          onClick={callback || null}
          to={!callback ? "/fq/" + normalizeFq(fq) : null}
        >
          <u>{normalizeFq(fq)}</u>
        </Link>
        .
      </p>
      <div className="btn-group my-1">
        {Boolean(fqMap.left) && (
          <Link
            to={"/fq/" + fqMap.left}
            type="button"
            className={`btn btn-${getButtonClass(
              leftDistance,
              difference < 0
            )}`}
          >
            {normalizeFq(fqMap.left)}
          </Link>
        )}
        {Boolean(fqMap.right) && (
          <Link
            to={"/fq/" + fqMap.right}
            type="button"
            className={`btn btn-${getButtonClass(
              rightDistance,
              difference > 0
            )}`}
          >
            {normalizeFq(fqMap.right)}
          </Link>
        )}
      </div>
    </div>
  );
}

export default NoFQData;
