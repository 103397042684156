import React from "react";

class FqCopy extends React.Component {
  render() {
    return (
      <div className="container">
        {DATA.map(([fq, names]) => {
          return <span className="fq-tile">{fq}</span>;
        })}
      </div>
    );
  }
}

const DATA = `
51.050/арта
51.100/арта/ Ямполівка-Невське
51.525/Арта
54.025/арта
57.975 /Арта/Ямполівка-Невське
65.700/ Арта
139.050/піхота/арта/міномет
142.500/?
146.325/?
147.200/Спірне?
149.148/?
149.800/?
410.160//мотопіхота/Макіївка
410.450/піхота/Кремінна р-н
410.725/Невське- Макіївка
412.537/Ямполівка
413.150/?
410.450/піхота/Кремінна р-н

410.725 /Невське- Макіївка
415.137/?
415.325/арта/Кремінна – Білогорівка
417.985/міномет
420.250/міномет/Ямполівка
421.075/Боброве- Муратове - Золотарівка
421.250/піхота/Невське
421.310/?
424.425/?
425.125/Терни р-н
425.250/?
425.325/Арта/Ямполівка
426.000
430.000/?
430.250/Сватово-Гончарівка-Зміївка
432.200/?
433.100
433.425/?
433.500/?
434.100/піхота
434.200/Невське- Макіївка
436.350/??
436.450/Макіївка?
439.725/ЧВК?
440.440/Ямполівка
441.440/?
442.500/арта
445.000/арта?/Спірне?
445.225/?
445.001/?/
450.125/КУЗЕМІВКА-ВОЛОДИМИРІВКА
451.001/?
452.125
459.125/Діброва – Білогорівка
460.325/?
462.525/захід Кремінної
463.000 ??
463.450/кадирор.

146.100/Рубіжне?
151.650/Спірне
152.150/Спірне-Вовчоярівка
146.100/РУБЕЖНОЕ
410.100/НАГІРНЕ-БЕЛОГОРОВКА?
415.325/Арта/Кременная – Белогоривка-Піщане?
424.425/Лисичанський-НПЗ-Золотарівка
432.200/Лисичанск НПЗ-Верхньокамянка?
434.775/Райгородка-Травневе
440.125/Дворічна-Новомильськ-Гряниківка
440.500/Райгородка-Джерельне?
451.000/Сватово, Оборотнівка?
`
  .trim()
  .split("\n")
  .map((str) => {
    let [fq, ...name] = str.split("/");
    return [fq, name.join("")];
  })
  .filter(([fq]) => fq !== "");

// console.log(DATA)
export default FqCopy;
