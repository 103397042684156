import { NavLink, Outlet } from "react-router-dom";
import React from "react";
import Activity from "./parts/Activity";
import Auth, { IframeCommunication } from "./parts/Auth";
import { shallowEqual, useSelector } from "react-redux";
import { isInIframe } from "../helpers";
import { useTeam } from "../hooks";

const App = () => {
  const { namesLoaded, authorised, authLoaded } = useSelector(
    ({ names, profile }) => ({
      namesLoaded: names.isLoaded,
      authorised: profile.authorised,
      authLoaded: profile.loaded,
    }),
    shallowEqual
  );
  const team = useTeam();

  return (
    <div className={"fq-wrapper"}>
      {isInIframe && <IframeCommunication />}
      <div className="tabs fq-nav">
        <div className="fq-nav__tabs">
          <div className="btn-group">
            <NavLink
              to="/"
              className={({ isActive }) =>
                "btn btn-mini btn-" + (isActive ? "primary" : "secondary")
              }
            >
              Пошук
            </NavLink>
            {/*<NavLink
              to="/cross"
              className={({ isActive }) =>
                "btn btn-mini btn-" + (isActive ? "primary" : "secondary")
              }
            >
              Кросс-аналіз
            </NavLink>*/}
            <NavLink
              to="/map"
              className={({ isActive }) =>
                "btn btn-mini btn-" + (isActive ? "primary" : "secondary")
              }
            >
              Карта
            </NavLink>
            {team === "osb49" && (
              <NavLink
                to="/doc"
                className={({ isActive }) =>
                  "btn btn-mini btn-" + (isActive ? "primary" : "secondary")
                }
              >
                Інструкції
              </NavLink>
            )}
            {team === "osb49" && (
              <NavLink
                to="/decoder"
                className={({ isActive }) =>
                  "btn btn-mini btn-" + (isActive ? "primary" : "secondary")
                }
              >
                Дешифратор
              </NavLink>
            )}
          </div>
        </div>
        <div className="fq-nav__auth">
          <Auth />
        </div>
      </div>

      <div className="fq-outlet">
        {!authLoaded && (
          <div className={"container"}>Перевіряємо доступи...</div>
        )}
        {authLoaded && !authorised && <NotAuthorised />}
        {authLoaded &&
          authorised &&
          (namesLoaded ? (
            <Outlet />
          ) : (
            <div className={"container"}>Завантажуємо...</div>
          ))}
      </div>
      <Activity />
    </div>
  );
};

const NotAuthorised = () => {
  return (
    <div className={"container"} style={{ paddingTop: "25px" }}>
      <h1>Тільки для військослужбовців</h1>
      <p>
        Для отримання персонального доступу до сервісу, заповніть{" "}
        <a
          href={"https://forms.gle/rff84pkfv8dY8g619"}
          target={"_blank"}
          style={{ textDecoration: "underline" }}
        >
          форму
        </a>{" "}
        або увійдіть вище в свій аккаунт
      </p>
    </div>
  );
};

export default App;
