import React, { useState } from "react";
import "../index.css";
import { Link, useParams } from "react-router-dom";

import { isInIframe, normalizeFq, normalizeFqToNum } from "../helpers";
import { FEATURE_FLAGS } from "../constants";
import DaystoryTab from "./tabs/DaystoryTab";
import NamesTab from "./tabs/NamesTab";
import { shallowEqual, useSelector } from "react-redux";
import _ from "lodash";
import FgField from "./parts/FgField";
import ChatTitle from "./parts/ChatTitle";
import NoFQData from "./parts/NoFQData";
import MessagesTab from "./tabs/MessagesTab/MessagesTab";

const FQ = () => {
  const [allowEditFQ, setAllowEditFQ] = useState(!isInIframe);
  const { fqID } = useParams();
  const fq = parseFloat(fqID);
  const email = useSelector(({ profile }) => profile.email);

  const newFeaturesEnabled = email === "murad@sapiense.com";

  const [activeTab, setActiveTab] = useState(
    newFeaturesEnabled ? "messages" : "names"
  );

  const { frequency, names } = useSelector(({ names, frequencies }) => {
    return {
      frequency: frequencies[fq],
      names: names.list[fq] || [],
    };
  }, shallowEqual);

  const tabsHeaders = [
    {
      title: "Позивні",
      tab: "names",
    },
    {
      title: "Інформація",
      tab: "info",
    },
    {
      title: "Історія",
      tab: "history",
    },
  ];
  if (newFeaturesEnabled) {
    tabsHeaders.unshift({
      title: "Повідомлення",
      tab: "messages",
    });
    // tabsHeaders.unshift({
    //   title: "Карта",
    //   tab: "map",
    // });
  }

  const TABS = {
    history: (
      <div>
        {/*        {FEATURE_FLAGS.daystory && (
          <>
            <DaystoryTab fq={fq} />
            <hr />
            <hr />
            <hr />
            <hr />
          </>
        )}*/}

        <FgField
          fq={fq}
          title={"Історія"}
          name={"history"}
          frequency={frequency}
        />
      </div>
    ),
    names: <NamesTab fqID={fqID} />,
    messages: <MessagesTab fqID={fqID} />,
    // map: <MapTab fqID={fqID} />,
    // --------------------------------------------------------------
    info: (
      <div className={"row"}>
        <div className="col-sm">
          <FgField
            fq={fq}
            title={"Ідентифікація (Хто вони?)"}
            name={"identification"}
            frequency={frequency}
          />
          <FgField
            fq={fq}
            title={"Локація (Де вони?)"}
            name={"location"}
            frequency={frequency}
          />
        </div>
        <div className="col-sm">
          <FgField
            fq={fq}
            title={"Суміжні частоти (З ким помітили взаємодію?)"}
            name={"neighbors"}
            frequency={frequency}
            // normalizeValue={(str) => {
            //   return str.replace(/(\d+)/);
            // }}
          >
            {({ value }) => {
              if (typeof value !== "string") return null;
              return (
                <div>
                  {value
                    .split(",")
                    .map((s) => s.trim())
                    .map((s) => parseFloat(s))
                    .filter((s) => !_.isNaN(s))
                    .map((s, i) => {
                      return (
                        <Link
                          to={"/fq/" + normalizeFq(s)}
                          key={s.toString()}
                          className={"fq-cross-link"}
                        >
                          {normalizeFq(s)}
                        </Link>
                      );
                    })}
                </div>
              );
            }}
          </FgField>
          <FgField
            fq={fq}
            title={"Замітки"}
            name={"notes"}
            frequency={frequency}
          />
        </div>
      </div>
    ),
  };

  const FQInfo =
    frequency && Object.values(frequency).join("").trim().length > 0;

  const showFqTabs = Boolean(allowEditFQ || names.length || FQInfo);

  const setFQEdit = () => {
    setAllowEditFQ(true);
  };

  return (
    <div className="fqview-container" key={fqID}>
      {!showFqTabs ? (
        <NoFQData fq={fq} callback={setFQEdit} />
      ) : (
        <>
          <div className="fqview-topcontainer">
            <div className="fqview-topcontainer__name">
              <h1>{normalizeFq(fqID)}</h1>
            </div>
            <div className="fqview-topcontainer__chattitle">
              <ChatTitle fq={normalizeFqToNum(fqID)} frequency={frequency} />
            </div>
          </div>
          <div className="nav nav-tabs fqview-tabs" id="nav-tab" role="tablist">
            {tabsHeaders.map(({ title, tab }) => {
              return (
                <button
                  key={tab}
                  className={"nav-link " + (activeTab === tab ? "active" : "")}
                  id="nav-home-tab"
                  type="button"
                  role="tab"
                  onClick={() => setActiveTab(tab)}
                >
                  {title}
                </button>
              );
            })}
          </div>
          <div className="fqview-tabcontainer">{TABS[activeTab]}</div>
        </>
      )}
    </div>
  );
};

export default FQ;
