import { __DEV__ } from "../constants";
import React from "react";

export default () => {
  return (
    <div className="doc">
      <iframe
        title={"VuhoDoc"}
        width={"100%"}
        height={"100%"}
        style={{ backgroundColor: "black" }}
        src={
          "https://docs.google.com/document/d/1kXyM5bTQEPi0tCYA3-ddGH3jdJhfW_eTEoz12wvtZco/edit"
        }
        frameBorder="0"
        // sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation"
        allow={"fullscreen"}
      />
    </div>
  );
};
