import { actionHandlers } from "../helpers";

export default actionHandlers(
  {
    USER_AUTH_SUCCESS: (state, payload) => {
      return {
        ...state,

        email: payload.email,
        access: payload.access,
        team: payload.team,

        authorised: true,
        loaded: true,
      };
    },
    USER_LOGOUT_SUCCESS: (state, payload) => {
      return {
        ...state,
        authorised: false,
      };
    },
    USER_AUTH_EMPTY: (state, payload) => {
      return {
        ...state,
        authorised: false,
        loaded: true,
      };
    },
  },
  {
    authorised: false,
    loaded: false,
    email: null,
    access: null,
    team: null,
  }
);
