import { _normalizeNames, normalizeFq } from "../../helpers";
import { useTeam } from "../../hooks";
import { useWriteAccess } from "../../hooks";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { InfoAbsent } from "../parts/FgField";
import TextareaAutosize from "react-textarea-autosize";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";

const NamesTab = ({ fqID }) => {
  const fq = parseFloat(fqID);
  const writeAccess = useWriteAccess();

  const namesFieldRef = useRef(null);
  const team = useTeam();

  const { cross, names } = useSelector(({ names, profile, frequencies }) => {
    return {
      cross: names.cross,
      names: names.list[fq] || [],
    };
  }, shallowEqual);

  const normalizedFqID = normalizeFq(fqID);

  const list = cross
    .map(([fq1, fq2, cross]) => [normalizeFq(fq1), normalizeFq(fq2), cross])
    .filter(
      ([fq1, fq2, cross]) => fq1 === normalizedFqID || fq2 === normalizedFqID
    )
    .filter(([fq1, fq2, cross]) => cross.length > 1)
    .map(([fq1, fq2, cross]) => [fq1 === normalizedFqID ? fq2 : fq1, cross]);

  useEffect(() => {
    // if logout, it can be not exist
    if (namesFieldRef.current) {
      const end = namesFieldRef.current.value.length;
      namesFieldRef.current.setSelectionRange(end, end);
      namesFieldRef.current.focus();
    }
  }, []);

  const namesJoined = names.join(", ");
  const [namesValue, setNamesValue] = useState(namesJoined);
  useEffect(() => {
    setNamesValue(namesJoined);
  }, [namesJoined]);

  return (
    <>
      <div style={{ marginTop: "10px", marginBottom: "25px" }}>
        <b>Позивні:({names.length}): </b>
        <br />
        {!writeAccess && (
          <div>{namesJoined ? namesJoined : <InfoAbsent />}</div>
        )}
        {/*// TODO: merge textarea with fqfield*/}
        {writeAccess && (
          <TextareaAutosize
            spellCheck="false"
            name=""
            id=""
            cols="30"
            rows="5"
            ref={namesFieldRef}
            className="form-control fq-names-textarea"
            value={namesValue}
            onChange={({ target }) => setNamesValue(target.value)}
            onKeyDown={(ev) => {
              // Enter should blur
              if (window.event.keyCode === 13) {
                ev.preventDefault();
                namesFieldRef.current.blur();
              }
            }}
            onBlur={({ target }) => {
              if (namesJoined === target.value) return;
              // console.log(parseFloat(fqID).toString())
              setDoc(
                doc(db, "names", team),
                {
                  [fq]: _normalizeNames(target.value).join(", "),
                },
                { merge: true }
              );
            }}
          />
        )}
      </div>
      <h5>Кросс-аналіз:</h5>
      <div style={{ marginBottom: "20px" }} className={"fq-cross-links"}>
        {list.length > 0 &&
          list.map(([fq, cross]) => {
            return <FqCross fq={fq} cross={cross} key={fq} />;
          })}

        {list.length === 0 && (
          <i>
            не знайденно пересічень ( показуються тільки ті, в яких два або
            більше
          </i>
        )}
      </div>
    </>
  );
};

const FqCross = ({ fq, cross }) => {
  const chat_title = useSelector(
    ({ frequencies }) => (frequencies[fq] || {}).chat_title
  );
  // console.log(fq, chat_title);
  const navigate = useNavigate();
  return (
    <span key={fq} className={"fq-cross-link-container"}>
      <b
        className="fq-cross-link"
        onClick={() => navigate("/fq/" + normalizeFq(fq))}
      >
        {normalizeFq(fq)}
      </b>{" "}
      {chat_title ? <i>{`[${chat_title}] `}</i> : ""} {cross.join(", ")}
    </span>
  );
};
export default NamesTab;
