import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { collection, limit, orderBy, query, where } from "firebase/firestore";
import { db } from "./firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useEffect } from "react";

export const useWriteAccess = () => {
  const access = useSelector(({ profile }) => profile.access);
  return access === "writer" || access === "admin";
};
export const useTeam = () => {
  return useSelector(({ profile }) => profile.team);
};
export const useAuthorised = () => {
  return useSelector(({ profile }) => profile.authorised);
};
// --------------------------------------------------------------
export const useDocs = (s, options, { selector, transform }) => {
  options = _.defaults({}, options, {
    initialValue: [],
  });
  let args = [];
  const team = useTeam();
  const cache = useSelector(selector);

  args = [collection(db, s.collection), where("team", "==", team)];
  if (_.isArray(s.where)) {
    if (s.where.length === 3 && typeof s.where[1] === "string") {
      args.push(where(...s.where));
    } else {
      s.where.forEach((rules) => {
        if (rules.length === 3 && typeof rules[1] === "string") {
          args.push(where(...rules));
        }
      });
    }
  }
  if (_.isArray(s.orderBy)) {
    if (s.orderBy[1] === "asc" || s.orderBy[1] === "desc") {
      args.push(orderBy(...s.orderBy));
    } else {
      s.orderBy.forEach((rules) => {
        if (rules[1] === "asc" || rules[1] === "desc") {
          args.push(orderBy(...rules));
        }
      });
    }
  }
  if (_.isNumber(s.limit)) {
    args.push(limit(s.limit));
  }
  const [storedValue, loading, error, snapshot] = useCollectionData(
    query(...args),
    options
  );
  snapshot && console.log(snapshot.docChanges());

  const dispatch = useDispatch();
  useEffect(() => {
    if (loading || error) return;
    // TODO: get changes from snapshot, not value
    const newDocs = transform ? transform(storedValue) : value;
    dispatch({
      type: "DOCS_CACHE",
      payload: {
        collection: s.collection,
        docs: newDocs,
      },
    });
  }, [storedValue]);

  let value = loading
    ? cache
    : transform
    ? transform(storedValue)
    : storedValue;

  return [value, loading, error];
};
