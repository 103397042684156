import { initializeApp } from "firebase/app";
import { doc, getDoc, getFirestore, onSnapshot } from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import _ from "lodash";
import store from "./store";

// --------------------------------------------------------------
const USE_TEST_DB = true;
// --------------------------------------------------------------

const firebaseConfigTest = {
  apiKey: "AIzaSyByAR7e4b-YpmxDHTJpqQhdzL9FYOIny2E",
  authDomain: "vuho-test.firebaseapp.com",
  databaseURL:
    "https://vuho-test-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "vuho-test",
  storageBucket: "vuho-test.appspot.com",
  messagingSenderId: "459276171532",
  appId: "1:459276171532:web:6ffcaa374e07786d7a3129",
};
const firebaseConfig = {
  apiKey: "AIzaSyC-LSx0SnjCkJpkLJgaCmWD7HSFxGV9mIo",
  authDomain: "radio-431c0.firebaseapp.com",
  projectId: "radio-431c0",
  storageBucket: "radio-431c0.appspot.com",
  messagingSenderId: "1000677935483",
  appId: "1:1000677935483:web:df97cc7067e6c8c0f415de",
};

// Initialize Firebase
const app = initializeApp(
  window.location.hostname !== "radio.sapiense.com" && USE_TEST_DB
    ? firebaseConfigTest
    : firebaseConfig
);

export const db = getFirestore(app);

export const auth = getAuth();

// --------------------------------------------------------------

export const subscribeCommonData = ({ dispatch, team }) => {
  console.log("[subscribeCommonData]");

  onSnapshot(doc(db, "names", team), (names) => {
    dispatch({
      type: "NAMES_SNAPSHOT",
      payload: _.pickBy(names.data(), (value, key) => value !== "") || {},
    });
  });
  onSnapshot(doc(db, "frequencies", team), (frequencies) => {
    dispatch({
      type: "FREQUENCIES_SNAPSHOT",
      payload: frequencies.data() || {},
    });
  });

  // onSnapshot(collection(db, "frequencies"), (input) => {
  //   const { toUpdate, toRemove } = groupChangesFromCollectionSnapshot(input);
  //
  //   if (!_.isEmpty(toUpdate)) {
  //     dispatch({
  //       type: "FREQUENCIES_UPDATE",
  //       payload: toUpdate,
  //     });
  //   }
  //   if (!_.isEmpty(toRemove)) {
  //     dispatch({
  //       type: "FREQUENCIES_REMOVE",
  //       payload: _.keys(toRemove),
  //     });
  //   }
  // });
};

auth.onAuthStateChanged(async (user) => {
  if (user) {
    let payload = {
      email: user.email,
      uid: user.uid,
      team: "osb49",
      access: "reader",
    };
    let extra;
    try {
      extra = await getDoc(doc(db, "users", user.email));
    } catch (err) {
      alert(
        "Помилка доступу. Зверніться до адміністратору щоб вам надали права"
      );
      store.dispatch({
        type: "USER_AUTH_EMPTY",
      });
      await signOut(auth);
      return;
    }

    const extraData = extra.data();

    if (extra.exists()) {
      payload = {
        ...payload,
        ...extraData,
      };
      if (!extraData.team) {
        payload.access = "reader";
      }
    }

    store.dispatch({
      type: "USER_AUTH_SUCCESS",
      payload,
    });
  } else {
    store.dispatch({
      type: "USER_AUTH_EMPTY",
    });
  }
});
