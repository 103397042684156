import React, { useEffect, useReducer, useRef, useState } from "react";
import { __DEV__ } from "../constants";
import {
  artaIcon,
  mortarIcon,
  reactiveIcon,
  targetIcon,
} from "./maptools/assets";
import copy from "copy-to-clipboard";
import CoordsRow from "./CoordsRow";
import conversionReducer, { DEFAULT, PROJ } from "./conversionReducer";
import Map from "./Map";
import { formatCK42, fullscreenEffect } from "../helpers";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import _ from "lodash";
import { calcDistance } from "./maptools";
import { useNavigate } from "react-router-dom";
import CopyFeedback from "../ui/CopiedTooltip";

const ICONS = {
  target: targetIcon,
  arta: artaIcon,
  mortarIcon: mortarIcon,
  reactive: reactiveIcon,
};
const ICONS_NAME = {
  target: "Противник надав ціль для обстрілу",
  arta: "Артилерія",
  mortar: "Міномет",
  reactive: "Реактивний снаряд",
};

const fetchElevation = ({ lat, lng }) => {
  return fetch(
    `https://elevation-api-l2gy2ciffq-ew.a.run.app/?latitude=${lat}&longitude=${lng}`
  )
    .then((res) => res.json())
    .then((data) => {
      if (!_.isObject(data) || typeof data.elevation !== "number") {
        return Promise.reject("Not valid response from elevation service");
      }
      return data;
    });
};

const generateObjectsForMap = ({ from, to }) => {
  let objects = [];
  if (
    typeof from[PROJ.WGS84].lat === "string" &&
    typeof to[PROJ.WGS84].lat === "string"
  ) {
    objects.push(
      (() => {
        // const point = {
        //   key: "random",
        //   type: "point",
        //   label: `${lat},${lng}`,
        //   value: {
        //     lat,
        //     lng,
        //   },
        // };

        const point = {
          key: "234234",
          type: "trajectory",
          label: ``,
          from: {
            lat: from[PROJ.WGS84].lat,
            lng: from[PROJ.WGS84].lng,
          },
          to: {
            lat: to[PROJ.WGS84].lat,
            lng: to[PROJ.WGS84].lng,
          },
        };
        // if (icon) point.icon = ICONS[icon];
        // console.log(point.icon);
        return point;
      })()
    );
  }
  if (typeof from[PROJ.WGS84].lat === "string" && to[PROJ.WGS84].lat == null) {
    objects.push(
      (() => {
        const point = {
          key: "random",
          type: "point",
          label: `${formatCK42(from[PROJ.CK42].x)}, ${formatCK42(
            from[PROJ.CK42].y
          )}`,
          value: {
            lat: from[PROJ.WGS84].lat,
            lng: from[PROJ.WGS84].lng,
          },
        };

        // if (icon) point.icon = ICONS[icon];
        // console.log(point.icon);
        return point;
      })()
    );
  }
  return objects;
};

const useElevationChange = (state, setElevation) => {
  const strToChange = [state[PROJ.WGS84].lat, state[PROJ.WGS84].lng].join(",");
  useEffect(() => {
    // return;
    if (state[PROJ.WGS84].lat == null) {
      setElevation(null);
      return;
    }
    fetchElevation(state[PROJ.WGS84]).then(
      (data) => {
        setElevation(data.elevation);
      },
      () => setElevation(-1)
    );
  }, [strToChange]);
};

const MapTab = () => {
  const [typeOfTrajectory, setTypeOfTrajectory] = useState("arta");
  const [from, dispatchFrom] = useReducer(conversionReducer, DEFAULT);
  const [to, dispatchTo] = useReducer(conversionReducer, DEFAULT);
  const [elevationFrom, setElevationFrom] = useState(null);
  const [elevationTo, setElevationTo] = useState(null);

  useElevationChange(from, setElevationFrom);
  useElevationChange(to, setElevationTo);

  useEffect(fullscreenEffect, []);

  // --------------------------------------------------------------
  const copyMessageTarget = () => {
    copy(
      `
${ICONS_NAME.target}
UTM
easting: ${from[PROJ.UTM].easting}
northing: ${from[PROJ.UTM].northing}
УСК-2000
x: ${formatCK42(from[PROJ.CK42].x)}
y: ${formatCK42(from[PROJ.CK42].y)}
${elevationFrom ? "Висота точки: " + elevationFrom.toFixed(1) + " м" : ""}
    `.trim()
    );
  };
  // --------------------------------------------------------------
  const copyMessageTrajectory = () => {
    copy(
      `
${ICONS_NAME[typeOfTrajectory]}
Вихід
UTM: ${from[PROJ.UTM].easting} ${from[PROJ.UTM].northing}
УСК-2000: ${formatCK42(from[PROJ.CK42].x)} ${formatCK42(from[PROJ.CK42].y)}
${elevationFrom ? "Висота: " + elevationFrom.toFixed(1) + " м" : ""}
Прихід
UTM: ${to[PROJ.UTM].easting} ${to[PROJ.UTM].northing}
УСК-2000: ${formatCK42(to[PROJ.CK42].x)} ${formatCK42(to[PROJ.CK42].y)}
${elevationTo ? "Висота: " + elevationTo.toFixed(1) + " м" : ""}
Дистанція ${
        calcDistance(
          from[PROJ.WGS84].lat,
          from[PROJ.WGS84].lng,
          to[PROJ.WGS84].lat,
          to[PROJ.WGS84].lng
        ).toFixed(1) + "км"
      }
    `.trim()
    );
  };
  // --------------------------------------------------------------
  //WGS84 (google maps)
  // ${from[PROJ.WGS84].lat}, ${from[PROJ.WGS84].lng}

  const objects = generateObjectsForMap({ from, to });

  useEffect(() => {
    __DEV__ &&
      dispatchFrom({
        type: PROJ.UTM,
        payload: {
          easting: "435661",
          northing: "5447226",
        },
      });
  }, []);
  const feedbackMonoRef = useRef();
  const feedbackTrajRef = useRef();

  const [tab, setTab] = React.useState(1);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const navigate = useNavigate();

  return (
    <div className="maptab">
      <div className="maptab__form">
        <Box sx={{ borderBottom: 1, marginBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="←"
              onClick={(ev) => {
                ev.preventDefault();
                navigate("/");
              }}
            />
            <Tab label="Траекторія руху снаряду" />
            <Tab label="Ціль обстрілу" />
            <Tab label="Пеленг" disabled />
          </Tabs>
        </Box>

        <div
          style={{ display: tab === 1 ? "block" : "none" }}
          className={"maptab__tab"}
        >
          <div className="maptab__form-toprow">
            {from[PROJ.WGS84].lat &&
              to[PROJ.WGS84].lat &&
              elevationFrom != null &&
              elevationTo != null && (
                <div
                  className="maptab__conversion_proj maptab__conversion_proj-copy"
                  title={"Копіювати повідомлення"}
                  onClick={() => {
                    copyMessageTrajectory();
                    feedbackTrajRef.current.show();
                  }}
                >
                  <CopyFeedback ref={feedbackTrajRef} />
                </div>
              )}
            <div className="maptab__conversion_proj maptab__conversion_proj-type">
              <FormControl fullWidth margin="dense">
                <InputLabel id="demo-simple-select-label">Тип</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={typeOfTrajectory}
                  label="Age"
                  margin="dense"
                  size={"small"}
                  onChange={({ target }) => {
                    setTypeOfTrajectory(target.value);
                  }}
                >
                  {["arta", "mortar", "reactive"].map((name) => {
                    return (
                      <MenuItem value={name} key={name}>
                        {ICONS_NAME[name]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <CoordsRow
            state={from}
            dispatch={dispatchFrom}
            elevation={elevationFrom}
            prepend={
              <div className="maptab__conversion_proj maptab__conversion_proj-text">
                <Typography variant={"subtitle1"}>Вихід</Typography>
                <Typography variant={"subtitle2"}>(weapon)</Typography>
              </div>
            }
          />
          <CoordsRow
            state={to}
            dispatch={dispatchTo}
            elevation={elevationTo}
            prepend={
              <div className="maptab__conversion_proj maptab__conversion_proj-text">
                <Typography variant={"subtitle1"}>Прихід</Typography>
                <Typography variant={"subtitle2"}>(impact)</Typography>
              </div>
            }
          />
        </div>
        <div
          style={{ display: tab === 2 ? "block" : "none" }}
          className={"maptab__tab"}
        >
          <CoordsRow
            state={from}
            dispatch={dispatchFrom}
            elevation={elevationFrom}
            prepend={
              from[PROJ.WGS84].lat &&
              elevationFrom != null && (
                <div
                  className="maptab__conversion_proj maptab__conversion_proj-copy"
                  title={"Копіювати повідомлення"}
                  onClick={() => {
                    copyMessageTarget();
                    feedbackMonoRef.current.show();
                  }}
                >
                  <CopyFeedback ref={feedbackMonoRef} />
                </div>
              )
            }
          />
        </div>
        <div
          style={{ display: tab === 3 ? "block" : "none" }}
          className={"maptab__tab"}
        >
          Тут будуть пеленги, уух
        </div>
      </div>

      <div className="maptab__map">
        <Map
          objects={objects}
          center={{
            lat: from[PROJ.WGS84].lat || 49.0228,
            lng: from[PROJ.WGS84].lng || 37.9649,
          }}
        />
      </div>
    </div>
  );
};

export default MapTab;
