import React, { useEffect, useRef, useState } from "react";
import { __DEV__ } from "../constants";
import _ from "lodash";

const Map = React.memo(({ objects, center }) => {
  const ref = useRef();
  const [ready, setReady] = useState(false);
  const payload = [...objects, { type: "center", value: center }];

  useEffect(() => {
    const initialListener = (ev) => {
      let action = {};
      try {
        action = JSON.parse(ev.data);
      } catch (err) {}
      if (typeof action.type === "string" && action.type === "READY") {
        ref.current.contentWindow.postMessage(payload, "*");
        setReady(true);
      }
    };
    window.addEventListener("message", initialListener, false);
    return () => {
      window.removeEventListener("message", initialListener, false);
    };
  }, []);

  useEffect(() => {
    if (ready) {
      ref.current.contentWindow.postMessage(payload, "*");
    }
  }, [objects, center]);

  return (
    <div className="map">
      <iframe
        title={"VuhoMap"}
        ref={ref}
        width={"100%"}
        height={"100%"}
        style={{ backgroundColor: "black" }}
        src={
          __DEV__
            ? "http://127.0.0.1:8080/dist/engine.html"
            : "https://map.sapiense.com/engine.html"
        }
        frameBorder="0"
        // sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation"
        allow={"fullscreen"}
      />
    </div>
  );
}, _.isEqual);

export default Map;
