import React from "react";
import TextField from "@mui/material/TextField";

const PROJ = {
  CK42: "CK42",
  WGS84: "WGS84",
  UTM: "UTM",
};

const ConversionField = ({
  state,
  dispatch,
  name,
  proj,
  label,
  maxLength,
  width,
  disabled,
  format,
  number = true,
}) => {
  const value = state[proj][name] || "";
  const V = format ? format(value) : value;
  return (
    <div
      className="maptab__conversion_field"
      style={{ width: width || "auto" }}
    >
      <TextField
        disabled={disabled}
        margin="dense"
        size={"small"}
        id={`${proj}[${name}]`}
        // key={`${proj}[${name}]`}
        label={label || `${proj.toLowerCase()} ${name}`}
        type={number ? "number" : "text"}
        variant="standard"
        inputProps={{ maxLength }}
        InputLabelProps={{ shrink: true }}
        value={V}
        // value={}
        onInput={(e) => {
          const newValue =
            maxLength && number
              ? Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, maxLength)
              : e.target.value;

          if (newValue !== value) {
            dispatch({
              type: PROJ[proj],
              payload: {
                [name]: newValue,
              },
            });
          }
        }}
      />
    </div>
  );
};

export default ConversionField;
