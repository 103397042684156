import {
  _normalizeNames,
  actionHandlers,
  isEqualArrayOfStrings,
  normalizeNameForCross,
} from "../helpers";
import _ from "lodash";

export default actionHandlers(
  {
    NAMES_SNAPSHOT: (state, payload) => {
      // normalize
      let list = _.mapValues(payload, (v, fq) => {
        const normalized = _normalizeNames(v);
        // make shallow check from snapshot to prevent uneccesary rerenderings
        if (!state[fq]) return normalized;
        return isEqualArrayOfStrings(state[fq], normalized)
          ? state[fq]
          : normalized;
      });

      const sortedFqList = _.sortBy(_.keys(list), (fq) => {
        return parseFloat(fq);
      });

      const computed = (() => {
        const nameToFs = {};
        _.forEach(list, (listOfNames, fq) => {
          _.forEach(listOfNames, (n) => {
            if (nameToFs[n]) {
              nameToFs[n].push(fq);
            } else {
              nameToFs[n] = [fq];
            }
          });
        });
        let max = 1;
        _.forEach(nameToFs, (arr) => {
          if (arr.length > max) max = arr.length;
        });
        return {
          nameToFs,
          maxValue: max,
        };
      })();

      const cross = (() => {
        const crosses = [];

        const listArr = sortedFqList.map((fq) => {
          const listOfNames = list[fq];
          return [fq, _.uniq(listOfNames.map(normalizeNameForCross))];
        });
        // console.log(666)
        _.forEach(listArr, ([fq1, listOfNames], i1) => {
          // console.log(777)
          _.forEach(listArr, ([fq2, listOfNames2], i2) => {
            // console.log(888, i2, i1)
            if (i2 <= i1) return;
            // console.log(999, listOfNames2, listOfNames)
            const both = _.filter(listOfNames2, (n) => {
              return listOfNames.indexOf(n) !== -1;
            });
            if (both.length) {
              crosses.push([fq1, fq2, both]);
            }
          });
        });
        return _.orderBy(crosses, ([x, y, z]) => z.length, ["desc"]);
      })();

      return {
        list,
        computed,
        cross,
        sortedFqList,
        isLoaded: true,
      };
    },
  },
  {
    list: {},
    computed: {},
    cross: [],
    isLoaded: false,
  }
);
