import React from "react";
import ReactDOM from "react-dom/client";
import "@forevolve/bootstrap-dark/dist/css/bootstrap-dark.css";
import "./index.css";
import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider,
} from "react-router-dom";
import FqCopy from "./ui/archive/FqCopy";
import NamesCleaner from "./ui/archive/NamesCleaner.js";
import { Provider } from "react-redux";
import store from "./store";
import Table from "./ui/Table";
import Cross from "./ui/Cross";
import FQ from "./ui/FQ";
import FireStoreHelper, { Backup } from "./ui/secret/FireStoreHelper";

import { isInIframe } from "./helpers";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { blueGrey, deepOrange } from "@mui/material/colors";
import App from "./ui/App";
import ukLocale from "date-fns/locale/uk";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MapTab from "./Map";
import Doc from "./ui/Doc";
import Decoder from "./Decoder";

const createRouter = isInIframe ? createHashRouter : createBrowserRouter;

const router = createRouter([
  {},
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Table />,
      },
      {
        path: "/cross",
        element: <Cross />,
      },

      {
        path: "/fq/:fqID",
        element: <FQ />,
      },
      {
        path: "/doc",
        element: <Doc />,
      },
      {
        path: "decoder",
        element: <Decoder />,
      },
    ],
  },
  {
    path: "/map",
    element: <MapTab />,
  },
  // --------------------------------------------------------------
  {
    path: "/fq",
    element: <FqCopy />,
  },
  {
    path: "/names",
    element: <NamesCleaner />,
  },
  {
    path: "/secret/FireStoreHelper",
    element: <FireStoreHelper />,
  },
  {
    path: "/secret/Backup",
    element: <Backup />,
  },
  // --------------------------------------------------------------
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
// React.StrictMode
root.render(
  <>
    <Provider store={store}>
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
            primary: deepOrange,
            secondary: blueGrey,
          },
          typography: {
            fontSize: 11,
          },
        })}
      >
        <LocalizationProvider
          adapterLocale={ukLocale}
          dateAdapter={AdapterDateFns}
        >
          <RouterProvider router={router} />
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  </>
);
