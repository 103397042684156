import { actionHandlers } from "../helpers";
import isSameDay from "date-fns/isSameDay";
import { updateCollection } from "../helpers";

export default actionHandlers(
  {
    DOCS_CACHE: updateCollection({
      name: "daystory",
      match: (prev, next) => prev.fq === next.fq && prev.date === next.date,
    }),
  },
  []
);
